<template>
  <v-sheet color="white" elevation="1" rounded class="my-1">
    <v-card>
      <v-card-title>
        <span class="text-uppercase font-weight-bold text-subtitle-1"> {{ title }} </span>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <div v-if="archivo.ruta">
          <v-btn text class="text-no-style py-6" @click="descargarArchivoProceso({ruta_documento: archivo.ruta})">
            
            <span class="text-h6" :title="archivo?.nombre">{{ recortarString(archivo?.nombre, 30) || "Archivo.pdf" }}</span>
            <v-icon right dark> mdi-download </v-icon>
          </v-btn>
        </div>
        <v-alert v-else type="info" dense class="my-0">
          No se ha cargado el documento
        </v-alert>
      </v-card-text>
    </v-card>
  </v-sheet>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "CardDownloadComponent",

  props: {
    title: {
      type: String,
      default: "",
    },
    archivo: {
      type: Object,
      default: {},
    },
  },

  data: () => ({}),

  methods: {
    ...mapActions("procesoCompraDoc", ["descargarArchivoProceso"]),
    recortarString: function (string, length) {
      if (string?.length > length) {
        return string.substring(0, length) + "...";
      } else {
        return string;
      }
    },
  },
};
</script>

<style>
</style>