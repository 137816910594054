<template>
    <section>
        <v-row>
            <v-col cols="12" sm="12" md="6">
                <v-autocomplete label="Tipo de documento" :items="documentos" item-value="id" item-text="nombre"
                    outlined clearable hide-details v-model="filters.id_tipo_documento" />
            </v-col>
            <v-col cols="12" sm="12" md="3" align-self="center">
                <v-btn dark block color="secondary" @click="(page = 1), obtenerTipoDocumento()">Buscar</v-btn>
            </v-col>
            <v-col cols="12" sm="12" md="3" align-self="center">
                <v-btn outlined block color="secondary" @click="clearFiltros()">Limpiar</v-btn>
            </v-col>

        </v-row>

        <v-data-table class="mt-7 mb-5" :headers="headers" item-key="id" :items="items">
            <template v-slot:[`item.Usuario`]="{ item }">
                {{ item.Usuario.Empleado?.Persona.primer_nombre }}
                {{ item.Usuario.Empleado?.Persona.primer_apellido }}
            </template>
            <template v-slot:[`item.fecha`]="{ item }">
                {{ formatDate(item.fecha_de_modificacion) }}
            </template>
            <template v-slot:[`item.observacion`]="{ item }">
                <v-chip v-if="item.observacion" label class="px-2 text-capitalize">{{ item.observacion }}</v-chip>
                <p v-else class="mb-0">-</p>
            </template>
            <template v-slot:[`item.acciones`]="{ item }">
                <v-btn icon @click="DownloadFileFtn(item)">
                    <v-icon>mdi-download</v-icon>
                </v-btn>
            </template>
        </v-data-table>
    </section>
</template>
  
<script>
import moment from "moment";

export default {
    props: {
        procesoData: {type: Object}
    },
    data: () => ({
        headers: [
            {
                text: "Tipo de documento",
                value: "tdp.nombre",
                align: 'center',
            },
            {
                text: "Subido por",
                value: "Usuario",
                align: 'center',
            },
            {
                text: "Fecha de modificación",
                value: "fecha",
                align: 'center',
            },
            {
                text: "Observación",
                value: "observacion",
                align: 'center',
                sortable: false,
            },
            {
                text: "Acciones",
                value: "acciones",
                align: 'center',
                sortable: false,
            },
        ],
        filters: {
            id_tipo_documento: null,
        },
        items: [],
        itemsData: [],
        itemsOfertas: [],
        documentos: [],
        ctlTipoDocumento: [],
        documentoOfertas: [],
        idsExcluidos: [5001, 59, 73, 83, 210, 235, 236, 260, 53, 67, 205, 222, 60, 74, 84, 211, 227, 15007, 
        240, 243, 246, 58, 72, 82, 209, 226, 5001, 197, 104, 132, 180, 60, 74, 84, 211, 227, 168, 217, 158, 
        233, 181, 135, 154, 194, 119, 101],
    }),
    computed: {
        esAuditorInterno(){
            return this.haveRole('ROLE_AUDITOR_INTERNO_AUIN');
        },
    },
    methods: {
        clearFiltros() {
            this.filters.id_tipo_documento = null;
            this.obtenerTipoDocumento();
        },
        async obtenerTipoDocumento() {
            const { status, data } = await this.services.PacProcesos.getListTipoDocumento(
                this.$route.params.idProceso,
                { ...this.filters }
            );
            if (status == 200) {
                this.itemsData = data;
            }
            if(this.haveRole('ROLE_AUDITOR_INTERNO_AUIN')){
                this.itemsOfertas = this.itemsData.filter(documento => !this.idsExcluidos.includes(documento.id_tipo_documento))
                this.items = this.procesoData?.fecha_contratacion ? this.itemsData : this.itemsOfertas;
            } else {
                this.items = this.itemsData;
            }
        },

        async fetchTipoDocumento() {
            const response = await this.services.PacProcesos.getTipoDocumentoProceso(this.$route.params.idProceso);
            if (response) {
                const { data } = response;
                this.ctlTipoDocumento = data;
            }
            if(this.haveRole('ROLE_AUDITOR_INTERNO_AUIN')){
                this.documentoOfertas = this.ctlTipoDocumento.filter(documento => !this.idsExcluidos.includes(documento.id))
                this.documentos = this.procesoData?.fecha_contratacion ? this.ctlTipoDocumento : this.documentoOfertas;
            } else{
                this.documentos = this.ctlTipoDocumento
            }
        },
        async DownloadFileFtn(item) {
            const queryParams = {
                ruta: item.ruta_documento, 
                ...item.id_tipo_documento === 15007 && ({ disk: 'procesos' }),
            }
            const response = await this.services.PacProcesos.descargarDocumentoProceso(queryParams);

            if (!response) {
                this.temporalAlert({
                    show: true,
                    message: "Ocurrió un error al descargar el documento",
                    type: "error",
                });
                return;
            }

            const blob = new Blob([response.data], {
                type: response.headers["content-type"],
            });

            const file = new File([blob], item.ruta_documento, {
                type: response.headers["content-type"],
            });

            const link = document.createElement("a");
            link.setAttribute("download", item.ruta_documento);
            link.href = window.URL.createObjectURL(file);
            link.download = "";
            link.click();

        },
        formatDate(date) {
            return moment(date).format("DD-MM-YYYY HH:mm A");
        },
    },
    created() {
        this.fetchTipoDocumento();
        this.obtenerTipoDocumento();
    },
};
</script>
  
<style></style>